import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikProps } from "formik";
import { WithStyles } from "@material-ui/core";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  registerSnackbar: boolean;
  registerSnackbarResponse: {
    type?: CustomSnackbarType;
    message: string;
  };
  registerSuccess: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenEmailAccountSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  registerApiId: string = "";
  registerFormProps?: FormikProps<{
    restaurantName: string;
    email: string;
    password: string;
    agree: boolean;
  }> = undefined;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      loading: false,

      registerSnackbar: false,
      registerSnackbarResponse: {
        type: undefined,
        message: "",
      },
      registerSuccess: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await StorageProvider.get("token");
    const accountType = await StorageProvider.get("account_type");

    if (!token) {
      return;
    }

    switch (accountType) {
      case "KitchenAccount":
        this.props.navigation.navigate("DashboardKitchen");
        break;

      case "SuperAdminAccount":
        this.props.navigation.navigate("DashboardAdmin");
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  updateSignUpFormProps = (
    props: FormikProps<{
      restaurantName: string;
      email: string;
      password: string;
      agree: boolean;
    }>
  ) => {
    this.registerFormProps = props;
  };

  openRegisterSnackbar = () => {
    this.setState({
      registerSnackbar: true,
    });
  };

  closeRegisterSnackbar = () => {
    this.setState({
      registerSnackbar: false,
    });
  };

  handleRegisterSnackbar = () => {
    this.closeRegisterSnackbar();

    if (!this.state.registerSuccess) return;

    this.login();
  };

  register = async (values: {
    restaurantName: string;
    email: string;
    password: string;
    agree: boolean;
  }) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: "kitchen_account",
      attributes: {
        user_name: values.restaurantName,
        email: values.email,
        password: values.password,
      },
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/create_kitchen_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleRegister = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (
      responseJson.errors &&
      responseJson.errors[0]?.email &&
      this.registerFormProps
    ) {
      this.registerFormProps.setErrors({
        email: responseJson.errors[0]?.email,
      });

      return;
    }

    if (
      responseJson.errors &&
      responseJson.errors[0]?.password &&
      this.registerFormProps
    ) {
      this.registerFormProps.setErrors({
        password: responseJson.errors[0]?.password,
      });

      return;
    }

    if (responseJson.error || responseJson.errors) {
      this.setState({
        registerSnackbarResponse: {
          type: CustomSnackbarType.Error,
          message: "Error! Please try again",
        },
      });

      this.openRegisterSnackbar();
      return;
    }

    this.setState({
      registerSnackbarResponse: {
        type: CustomSnackbarType.Success,
        message: "Register Successfully",
      },
      registerSuccess: true,
    });

    this.openRegisterSnackbar();
  };

  login = () => {
    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };

  goTermsAndConditions = () => {};

  goPrivacyPolicy = () => {};
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.registerApiId) {
      this.handleRegister(responseJson);
    }

    // Customizable Area End
  }
}
