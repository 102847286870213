// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { WithStyles } from "@material-ui/core";

import { KeyboardEvent, MouseEventHandler } from "react";
import { ValidationSchema } from "./LocationBranchStatus.web";
import { Ref } from "yup";
import { FormikProps } from "formik";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

export enum BRANCH_STATUS {
  ALL = "",
  OPEN = "open",
  BUSY = "busy",
  CLOSED = "close",
}

type ResponseImage = { id: number; url: string };

export type BranchRaw = {
  id: string;
  type: "store";
  attributes: {
    name: string;
    branch_name: string | null;
    regular_schedule: string | null;
    special_schedule: string | null;
    about_us: string | null;
    city: string | null;
    location: string | null;
    longitude: number;
    latitude: number;
    address: string | null;
    full_address: string | null;
    kitchen_account_id: null;
    status: BRANCH_STATUS;
    status_message: string | null;
    images: ResponseImage[];
  };
};

export type Branch = {
  id: string | undefined;
  restaurant_name: string;
  street_name: string | undefined;
  city: string | undefined;
  locations: string | undefined;
  about: string | undefined;
  status: BRANCH_STATUS;
  regular_schedue: string | undefined;
  special_schedule: string | undefined;
  address: string | undefined;
  status_message: string | undefined;
  images: ResponseImage[];
  longitude: number;
  latitude: number;
};

type CountryCode = {
  id: string;
  type: "country_code_and_flag";
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  };
};

interface S {
  filter: string;
  search: string;
  isSidebarOpen: boolean;
  editID: string | null;
  branches: Branch[];
  loading: boolean;
  loadingWhenGetAllBranches: boolean;
  loadingWhenCreatingOrUpdating: boolean;
  loadingGetCountryCode: boolean;
  sort: "asc" | "desc";
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  statusCount: {
    all: number;
    open: number;
    busy: number;
    close: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  countryCodes: CountryCode[];
}

interface SS {}

export default class LocationBranchStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetBranchesCallId: string = "";
  apiGetAllBranchesCallId: string = "";
  apiAddBranchCallId: string = "";
  apiUpdateBranchCallId: string = "";
  apiGetCountryCodeCallId: string = "";
  formikRef: FormikProps<ValidationSchema> | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      filter: BRANCH_STATUS.ALL,
      isSidebarOpen: false,
      editID: null,
      branches: [],
      loading: false,
      loadingWhenGetAllBranches: false,
      loadingWhenCreatingOrUpdating: false,
      search: "",
      pagination: {
        page: 1,
        limit: 5,
        totalCount: 0,
        totalPages: 0,
      },
      sort: "desc",
      statusCount: {
        all: 0,
        open: 0,
        busy: 0,
        close: 0,
      },
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      countryCodes: [],
      loadingGetCountryCode: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  falsyHandler = (value: string | null) => {
    return value || undefined;
  };

  parseData = (rawData: BranchRaw): Branch => {
    const {
      id,
      attributes: {
        about_us,
        address,
        branch_name,
        city,
        name,
        status,
        regular_schedule,
        special_schedule,
        location,
        status_message,
        images,
      },
    } = rawData;

    return {
      id,
      restaurant_name: name,
      about: this.falsyHandler(about_us),
      city: this.falsyHandler(city),
      locations: this.falsyHandler(location),
      regular_schedue: this.falsyHandler(regular_schedule),
      special_schedule: this.falsyHandler(special_schedule),
      status,
      street_name: this.falsyHandler(branch_name),
      address: this.falsyHandler(address),
      status_message: this.falsyHandler(status_message),
      images: images,
      latitude: rawData.attributes.latitude,
      longitude: rawData.attributes.longitude,
    };
  };

  getAllBranches = () => {
    this.setState({ loadingWhenGetAllBranches: true });

    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllBranchesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationStoresApiEndPoint + "?per_page=9999"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranches = () => {
    this.setState({ loading: true });

    const searchParam = new URLSearchParams();
    searchParam.set("status", this.state.filter);
    searchParam.set("per_page", this.state.pagination.limit.toString());
    searchParam.set("page", this.state.pagination.page.toString());
    searchParam.set("name", this.state.search.toString());
    searchParam.set("sort_order", this.state.sort);

    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetBranchesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationStoresApiEndPoint + "?" + searchParam.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createBranch = async (values: ValidationSchema) => {
    const header = {
      token: localStorage.getItem("token"),
    };

    const formData = new FormData();

    Object.keys(values).forEach((formKey) => {
      const currentValue = values[formKey as unknown as keyof ValidationSchema];
      if (!currentValue) {
        return;
      }

      if (Array.isArray(currentValue) && currentValue.length > 0) {
        values.images.forEach((file: unknown) => {
          formData.append("images[]", file as Blob);
        });
      } else {
        formData.append(formKey, currentValue as string);
      }
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddBranchCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationStoresApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImagesOnUpdate = (
    initValue: (File | ResponseImage)[],
    currentValue: (File | ResponseImage)[]
  ) => {
    const newFiles = currentValue.filter(
      (item) => item instanceof File
    ) as File[];

    const removedFiles = initValue.filter((item) => {
      if (item instanceof File) {
        return false;
      }
      const isRemoved =
        currentValue.filter((elem) => "id" in elem && elem.id === item.id)
          .length === 0;
      return isRemoved;
    }) as ResponseImage[];

    return { newFiles, removedFiles };
  };

  updateBranch = async (values: ValidationSchema) => {
    if (!this?.formikRef?.dirty) {
      this.setState({ loadingWhenCreatingOrUpdating: false });
      return;
    }

    const header = {
      token: localStorage.getItem("token"),
    };

    const formData = new FormData();

    Object.keys(values).forEach((_key) => {
      const currentValue = values[_key as unknown as keyof ValidationSchema];

      const initValue = this.getEditBranch(this.state.editID as string)[
        _key as unknown as keyof ValidationSchema
      ];

      if (_key !== "images" && currentValue && currentValue !== initValue) {
        formData.append(_key, currentValue as string);
      }

      if (Array.isArray(currentValue) && Array.isArray(initValue)) {
        const { newFiles, removedFiles } = this.handleImagesOnUpdate(
          initValue as unknown as (File | ResponseImage)[],
          currentValue as unknown as (File | ResponseImage)[]
        );
        if (newFiles.length) {
          newFiles.forEach((file) => {
            formData.append("images[]", file);
          });
        }

        if (removedFiles.length) {
          removedFiles.forEach((file) => {
            formData.append("remove_image_ids[]", file.id + "");
          });
        }
      }
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateBranchCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationStoresApiEndPoint + "/" + this.state.editID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountryCode = () => {
    this.setState({ loadingGetCountryCode: true });

    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/country_code_and_flag"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    this.apiGetCountryCodeCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    this.getBranches();
    this.getAllBranches();
    this.getCountryCode();
  }

  handleAPIGetBranchesResponse = (responseJson?: {
    stores?: { data: BranchRaw[] };
    pagination: {
      current_page: number;
      total_entries: number;
      total_pages: number;
    };
    message?: string;
    errors?: [];
    error?: {};
  }) => {
    if (responseJson && responseJson?.stores && responseJson.stores?.data) {
      const branches = responseJson.stores.data.map(this.parseData);
      this.setState({
        branches: branches,
        loading: false,
        pagination: {
          limit: 5,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_entries,
          totalPages: responseJson.pagination.total_pages,
        },
      });
    }

    if (
      responseJson &&
      responseJson?.message &&
      responseJson.message === "No stores found"
    ) {
      this.setState({
        loading: false,
        branches: [],
        statusCount: {
          all: 0,
          busy: 0,
          close: 0,
          open: 0,
        },
      });
    }

    if (responseJson?.errors || responseJson?.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        this.props.t("Error! Please try again!")
      );
    }
  };

  handleAPICreateBranchResponse = (responseJson?: {
    data?: { attributes: {} };
    errors?: [];
    error?: {};
  }) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState(
        {
          loadingWhenCreatingOrUpdating: false,
          isSidebarOpen: false,
        },
        () => {
          this.getBranches();
          this.getAllBranches();
        }
      );
    }

    if (responseJson?.errors || responseJson?.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        this.props.t("Error! Please try again")
      );
    }
  };

  handleAPIUpdateBranchResponse = (responseJson: {
    data?: { attributes: {} };
    errors?: [];
    error?: {};
  }) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState(
        {
          loadingWhenCreatingOrUpdating: false,
          isSidebarOpen: false,
          editID: null,
        },
        () => {
          this.getBranches();
          this.getAllBranches();
        }
      );
    }

    if (responseJson?.errors || responseJson?.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        this.props.t("Error! Please try again")
      );
    }
  };

  handleAPIAllGetBranchesResponse = (responseJson?: {
    stores?: { data: BranchRaw[] };
    message?: string;
    errors?: [];
    error?: {};
  }) => {
    if (responseJson && responseJson?.stores && responseJson.stores?.data) {
      const branches = responseJson.stores.data;
      const open = this.countStatus(branches, BRANCH_STATUS.OPEN);
      const busy = this.countStatus(branches, BRANCH_STATUS.BUSY);
      const close = this.countStatus(branches, BRANCH_STATUS.CLOSED);

      this.setState({
        loadingWhenGetAllBranches: false,
        statusCount: {
          all: branches.length,
          open,
          busy,
          close,
        },
      });
    }

    if (
      responseJson &&
      responseJson?.message &&
      responseJson.message === "No stores found"
    ) {
      this.setState({
        loading: false,
        branches: [],
        statusCount: {
          all: 0,
          busy: 0,
          close: 0,
          open: 0,
        },
      });
    }

    if (responseJson?.errors || responseJson?.error) {
      this.setState({
        loading: false,
      });
    }
  };

  handleAPICallGetCountryCodeResponse = (responseJson: any) => {
    this.setState({ loadingGetCountryCode: false });

    if (responseJson && responseJson.data) {
      this.setState({ countryCodes: responseJson.data });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiGetBranchesCallId) {
        this.handleAPIGetBranchesResponse(responseJson);
      }

      if (apiRequestCallId === this.apiAddBranchCallId) {
        this.handleAPICreateBranchResponse(responseJson);
      }

      if (apiRequestCallId === this.apiUpdateBranchCallId) {
        this.handleAPIUpdateBranchResponse(responseJson);
      }

      if (apiRequestCallId === this.apiGetAllBranchesCallId) {
        this.handleAPIAllGetBranchesResponse(responseJson);
      }

      if (apiRequestCallId === this.apiGetCountryCodeCallId) {
        this.handleAPICallGetCountryCodeResponse(responseJson);
      }
    }
  }

  handleSubmit = (values: ValidationSchema) => {
    if (this.state.loadingWhenCreatingOrUpdating) {
      return;
    }

    this.setState({ loadingWhenCreatingOrUpdating: true });
    if (this.state.editID) {
      this.updateBranch(values);
    } else {
      this.createBranch(values);
    }
  };

  handleRefresh = () => {
    this.setState(
      {
        filter: "",
        search: "",
        pagination: {
          limit: 5,
          page: 1,
          totalCount: 0,
          totalPages: 0,
        },
      },
      () => {
        this.getBranches();
      }
    );
  };

  handleSort = () => {
    const sort = this.state.sort === "asc" ? "desc" : "asc";
    this.setState(
      {
        sort,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.getBranches();
      }
    );
  };

  changeMenuTab = (value: BRANCH_STATUS) => {
    this.setState(
      {
        filter: value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.getBranches();
        this.getAllBranches();
      }
    );
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getBranches();
      }
    );
  };

  handleKeydown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key !== "Enter") {
      return;
    }

    this.setState(
      {
        search: event.currentTarget.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.getBranches();
      }
    );
  };

  openSidebar: MouseEventHandler<HTMLSpanElement> = (event) => {
    event.stopPropagation();
    const editID = event.currentTarget.id || null;

    this.setState({ isSidebarOpen: true, editID });
  };

  getEditBranch = (branchdID: string) => {
    const neededBranch = this.state.branches.find(
      (item) => item.id === branchdID
    )!;

    const initImages = neededBranch.images as unknown as (Ref | null)[];

    return {
      about_us: neededBranch.about || "",
      city: neededBranch.city,
      location: neededBranch.locations,
      name: neededBranch.restaurant_name,
      status: neededBranch.status,
      branch_name: neededBranch.street_name,
      images: initImages,
      latitude: neededBranch.latitude,
      longitude: neededBranch.longitude,
      country_code: undefined,
    };
  };

  closeSidebar = () => {
    this.setState({ isSidebarOpen: false, editID: null });
  };

  countStatus = (branches: BranchRaw[], status: BRANCH_STATUS) => {
    return branches.filter((item) => item.attributes.status === status).length;
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  checkError = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && !!errors;
  };
}

// Customizable Area End
